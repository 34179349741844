@font-face {
  font-family: "ProductSans";
  src: local("ProductSans"), url(./assets/fonts/ProductSans.ttf);
}

@font-face {
  font-family: "ProductSansBold";
  src: local("ProductSansBold"), url(./assets/fonts/ProductSansBold.ttf);
}

@font-face {
  font-family: "Roboto-Black";
  src: local("Roboto-Black"), url(./assets/fonts/Roboto-Black.ttf);
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"), url(./assets/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"), url(./assets/fonts/Roboto-Light.ttf);
}
@font-face {
  font-family: "Roboto-Thin";
  src: local("Roboto-Thin"), url(./assets/fonts/Roboto-Thin.ttf);
}

.carousel-indicators li {
  background-color: rgb(112, 8, 8);
}

.navbar.transparent.navbar-inverse .navbar-inner {
  background: transparent !important;
}

/* .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  background-color: black;
  border: 2px solid #000000;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: red;
  font-size: 17px;
} */

#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border-radius: 5px;
  border: 0;

  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
}

body {
  font-family: "ProductSans";

  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
